<ng-container *ngIf="state$ | async as state">
  <div
    class="row justify-content-center calendar-event__stepper__container d-none d-sm-flex"
  >
    <ng-container *ngFor="let step of steps; let index = index">
      <div class="col-auto calendar-event__stepper">
        <div
          class="calendar-event__stepper__header"
          [ngClass]="
            state.currentStep === index
              ? 'current'
              : state.currentStep > index
              ? 'done'
              : ''
          "
        >
          <wr-icon
            [type]="steps[index].icon.type"
            [color]="steps[index].icon.color"
            [size]="enums.IconSizes.LG"
            [shade]="steps[index].icon.shade"
            class="calendar-event__stepper__header__icon"
          ></wr-icon>

          <span class="calendar-event__stepper__header__line"></span>
        </div>
        <div class="calendar-event__stepper__content">
          {{ "Calendar.blkStepper.lblStep" | translate }} {{ index + 1 }}

          <span class="d-block txt--content txt--neutral--3">
            {{ "Calendar.blkStepper.titleStep" + (index + 1) | translate }}
          </span>
        </div>
      </div>
    </ng-container>

    <div class="col-auto calendar-event__stepper">
      <div class="calendar-event__stepper__header">
        <wr-icon
          [type]="enums.IconTypes.Trophy"
          [color]="enums.IconColors.Neutral"
          [size]="enums.IconSizes.LG"
          [shade]="enums.IconShades.Shade8"
          class="calendar-event__stepper__header__icon"
        ></wr-icon>
      </div>
    </div>
  </div>
</ng-container>
