/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetBookingInformationResponse } from '../models/get-booking-information-response';
import { GetDayAvailabilitiesResponse } from '../models/get-day-availabilities-response';
import { CreateNewAppointmentResponse } from '../models/create-new-appointment-response';
import { NewAppointmentRequest } from '../models/new-appointment-request';
import { GetCancelBookingInformationsResponse } from '../models/get-cancel-booking-informations-response';
import { CancelAppointmentResponse } from '../models/cancel-appointment-response';
import { CancelAppointmentCandidateRequest } from '../models/cancel-appointment-candidate-request';
@Injectable({
  providedIn: 'root',
})
class CalendarEventsService extends __BaseService {
  static readonly GetBookingInformationsPath = '/api/calendar-events/{user}/{eventModelUrl}/{eventPart}';
  static readonly GetDayAvailabilitiesPath = '/api/calendar-events/{id}/availabilities';
  static readonly NewAppointmentPath = '/api/calendar-events/{id}/appointments';
  static readonly GetCancelBookingInformationsPath = '/api/calendar-events/cancel/{eventId}';
  static readonly CancelAppointmentPath = '/api/calendar-events/cancel/{calendarEventId}';
  static readonly ValidateAppointmentAvailabilityPath = '/api/calendar-events/{id}/appointments/validate-slot';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Intended to be called by candidate, to get event information
   * Example: /rtusseau.groupe-ronac/333/a8093ea5-bad1-46f6-ad88-23e4742bbb8e
   * @param params The `CalendarEventsService.GetBookingInformationsParams` containing the following parameters:
   *
   * - `user`: Example: rtusseau.groupe-ronac (== UserEmailPrefix.EmailSubdomain)
   *
   * - `eventPart`: Example: a8093ea5-bad1-46f6-ad88-23e4742bbb8e (CalendarEventId) (optional)
   *
   * - `eventModelUrl`: Example: 333 (== CalendarEventModelId)
   *
   * @return OK
   */
  GetBookingInformationsResponse(params: CalendarEventsService.GetBookingInformationsParams): __Observable<__StrictHttpResponse<GetBookingInformationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;



    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/calendar-events/${encodeURIComponent(String(params.user))}/${encodeURIComponent(String(params.eventModelUrl))}/${encodeURIComponent(String(params.eventPart))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetBookingInformationResponse>;
      })
    );
  }
  /**
   * Intended to be called by candidate, to get event information
   * Example: /rtusseau.groupe-ronac/333/a8093ea5-bad1-46f6-ad88-23e4742bbb8e
   * @param params The `CalendarEventsService.GetBookingInformationsParams` containing the following parameters:
   *
   * - `user`: Example: rtusseau.groupe-ronac (== UserEmailPrefix.EmailSubdomain)
   *
   * - `eventPart`: Example: a8093ea5-bad1-46f6-ad88-23e4742bbb8e (CalendarEventId) (optional)
   *
   * - `eventModelUrl`: Example: 333 (== CalendarEventModelId)
   *
   * @return OK
   */
  GetBookingInformations(params: CalendarEventsService.GetBookingInformationsParams): __Observable<GetBookingInformationResponse> {
    return this.GetBookingInformationsResponse(params).pipe(
      __map(_r => _r.body as GetBookingInformationResponse)
    );
  }

  /**
   * Get availabilities for a selected day
   * @param params The `CalendarEventsService.GetDayAvailabilitiesParams` containing the following parameters:
   *
   * - `id`: calendar event model id
   *
   * - `eventId`: event's id
   *
   * - `date`: day
   *
   * @return OK
   */
  GetDayAvailabilitiesResponse(params: CalendarEventsService.GetDayAvailabilitiesParams): __Observable<__StrictHttpResponse<GetDayAvailabilitiesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.eventId != null) __params = __params.set('eventId', params.eventId.toString());
    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/calendar-events/${encodeURIComponent(String(params.id))}/availabilities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetDayAvailabilitiesResponse>;
      })
    );
  }
  /**
   * Get availabilities for a selected day
   * @param params The `CalendarEventsService.GetDayAvailabilitiesParams` containing the following parameters:
   *
   * - `id`: calendar event model id
   *
   * - `eventId`: event's id
   *
   * - `date`: day
   *
   * @return OK
   */
  GetDayAvailabilities(params: CalendarEventsService.GetDayAvailabilitiesParams): __Observable<GetDayAvailabilitiesResponse> {
    return this.GetDayAvailabilitiesResponse(params).pipe(
      __map(_r => _r.body as GetDayAvailabilitiesResponse)
    );
  }

  /**
   * Set a new appointment based on WeRecruit.Data.Booking.Models.CreateNewAppointmentRequest infos
   * @param params The `CalendarEventsService.NewAppointmentParams` containing the following parameters:
   *
   * - `id`: calendar event model id
   *
   * - `body`: creation request info
   *
   * @return OK
   */
  NewAppointmentResponse(params: CalendarEventsService.NewAppointmentParams): __Observable<__StrictHttpResponse<CreateNewAppointmentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/calendar-events/${encodeURIComponent(String(params.id))}/appointments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateNewAppointmentResponse>;
      })
    );
  }
  /**
   * Set a new appointment based on WeRecruit.Data.Booking.Models.CreateNewAppointmentRequest infos
   * @param params The `CalendarEventsService.NewAppointmentParams` containing the following parameters:
   *
   * - `id`: calendar event model id
   *
   * - `body`: creation request info
   *
   * @return OK
   */
  NewAppointment(params: CalendarEventsService.NewAppointmentParams): __Observable<CreateNewAppointmentResponse> {
    return this.NewAppointmentResponse(params).pipe(
      __map(_r => _r.body as CreateNewAppointmentResponse)
    );
  }

  /**
   * Get cancelling informations
   * @param eventId event's id
   * @return OK
   */
  GetCancelBookingInformationsResponse(eventId: string): __Observable<__StrictHttpResponse<GetCancelBookingInformationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/calendar-events/cancel/${encodeURIComponent(String(eventId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetCancelBookingInformationsResponse>;
      })
    );
  }
  /**
   * Get cancelling informations
   * @param eventId event's id
   * @return OK
   */
  GetCancelBookingInformations(eventId: string): __Observable<GetCancelBookingInformationsResponse> {
    return this.GetCancelBookingInformationsResponse(eventId).pipe(
      __map(_r => _r.body as GetCancelBookingInformationsResponse)
    );
  }

  /**
   * Cancel an appointment
   * @param params The `CalendarEventsService.CancelAppointmentParams` containing the following parameters:
   *
   * - `calendarEventId`: event's id
   *
   * - `body`: request
   *
   * @return OK
   */
  CancelAppointmentResponse(params: CalendarEventsService.CancelAppointmentParams): __Observable<__StrictHttpResponse<CancelAppointmentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/calendar-events/cancel/${encodeURIComponent(String(params.calendarEventId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CancelAppointmentResponse>;
      })
    );
  }
  /**
   * Cancel an appointment
   * @param params The `CalendarEventsService.CancelAppointmentParams` containing the following parameters:
   *
   * - `calendarEventId`: event's id
   *
   * - `body`: request
   *
   * @return OK
   */
  CancelAppointment(params: CalendarEventsService.CancelAppointmentParams): __Observable<CancelAppointmentResponse> {
    return this.CancelAppointmentResponse(params).pipe(
      __map(_r => _r.body as CancelAppointmentResponse)
    );
  }

  /**
   * Indicate if the slot is still available. Used before M:WeRecruit.Booking.Controllers.CalendarEventsController.NewAppointment(System.Int32,WeRecruit.Booking.Models.NewAppointmentRequest)
   * @param params The `CalendarEventsService.ValidateAppointmentAvailabilityParams` containing the following parameters:
   *
   * - `id`: calendat event model id
   *
   * - `body`: creation request info
   *
   * @return OK
   */
  ValidateAppointmentAvailabilityResponse(params: CalendarEventsService.ValidateAppointmentAvailabilityParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/calendar-events/${encodeURIComponent(String(params.id))}/appointments/validate-slot`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Indicate if the slot is still available. Used before M:WeRecruit.Booking.Controllers.CalendarEventsController.NewAppointment(System.Int32,WeRecruit.Booking.Models.NewAppointmentRequest)
   * @param params The `CalendarEventsService.ValidateAppointmentAvailabilityParams` containing the following parameters:
   *
   * - `id`: calendat event model id
   *
   * - `body`: creation request info
   *
   * @return OK
   */
  ValidateAppointmentAvailability(params: CalendarEventsService.ValidateAppointmentAvailabilityParams): __Observable<boolean> {
    return this.ValidateAppointmentAvailabilityResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module CalendarEventsService {

  /**
   * Parameters for GetBookingInformations
   */
  export interface GetBookingInformationsParams {

    /**
     * Example: rtusseau.groupe-ronac (== UserEmailPrefix.EmailSubdomain)
     */
    user: string;

    /**
     * Example: a8093ea5-bad1-46f6-ad88-23e4742bbb8e (CalendarEventId) (optional)
     */
    eventPart: string;

    /**
     * Example: 333 (== CalendarEventModelId)
     */
    eventModelUrl: string;
  }

  /**
   * Parameters for GetDayAvailabilities
   */
  export interface GetDayAvailabilitiesParams {

    /**
     * calendar event model id
     */
    id: number;

    /**
     * event's id
     */
    eventId?: string;

    /**
     * day
     */
    date?: string;
  }

  /**
   * Parameters for NewAppointment
   */
  export interface NewAppointmentParams {

    /**
     * calendar event model id
     */
    id: number;

    /**
     * creation request info
     */
    body?: NewAppointmentRequest;
  }

  /**
   * Parameters for CancelAppointment
   */
  export interface CancelAppointmentParams {

    /**
     * event's id
     */
    calendarEventId: string;

    /**
     * request
     */
    body?: CancelAppointmentCandidateRequest;
  }

  /**
   * Parameters for ValidateAppointmentAvailability
   */
  export interface ValidateAppointmentAvailabilityParams {

    /**
     * calendat event model id
     */
    id: number;

    /**
     * creation request info
     */
    body?: NewAppointmentRequest;
  }
}

export { CalendarEventsService }
