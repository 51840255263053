import { Component } from '@angular/core';
import { BadgeService } from 'core-components';
import { takeUntil, tap } from 'rxjs/operators';
import {
  IconColors,
  IconShades,
  IconSizes,
  IconTypes,
  WithUnsubscribe,
} from 'shared';
import { CalendarEventService } from '../../_services';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent extends WithUnsubscribe() {
  public state$ = this._service.state$;
  enums = { IconColors, IconTypes, IconShades, IconSizes };

  steps: Array<any> = [];

  constructor(
    private readonly _service: CalendarEventService,
    public readonly badgeService: BadgeService
  ) {
    super();
  }

  ngOnInit(): void {
    this.state$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((state) => this.manageSteps(state))
      )
      .subscribe();
  }

  manageSteps(state) {
    switch (state.currentStep) {
      case 0:
        this.steps[0] = {
          badgeColor: this.badgeService.colors.Primary,
          icon: {
            type: IconTypes.ArrowRight,
            color: IconColors.Primary,
            shade: IconShades.Shade11,
          },
        };

        this.steps[1] = {
          badgeColor: this.badgeService.colors.Ghost,
          icon: {
            type: IconTypes.Lock,
            color: IconColors.Neutral,
            shade: IconShades.Shade8,
          },
        };
        break;
      case 1:
        this.steps[0] = {
          badgeColor: this.badgeService.colors.Success,
          icon: {
            type: IconTypes.Check,
            color: IconColors.Success,
            shade: IconShades.Shade10,
          },
        };
        this.steps[1] = {
          badgeColor: this.badgeService.colors.Primary,
          icon: {
            type: IconTypes.ArrowRight,
            color: IconColors.Primary,
            shade: IconShades.Shade11,
          },
        };
        break;
    }
  }
}
