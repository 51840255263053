<ng-container *wrLoader="service.isLoading">
  <ng-container *ngIf="state$ | async as state">
    <form [formGroup]="state.form"></form>
    <div class="row calendar-event__logo-container">
      <div class="col-12 align-content-center calendar-event__logo-wrap">
        <img [src]="state.informations.companyLogoUrl" />
      </div>
    </div>
    <app-stepper></app-stepper>
    <div class="row justify-content-center">
      <div class="col-12 col-md-4 col-lg-3 mb-5 px-xl-4">
        <div class="calendar-event__informations">
          <div class="calendar-event__informations__user-avatar">
            <wr-avatar
              [size]="enums.AvatarSizes.XXL"
              [url]="state.informations.userPictureUrl"
            >
            </wr-avatar>
          </div>
          <p class="calendar-event__informations__user-name">
            {{ state.informations.userName }}
          </p>
          <div
            class="calendar-event__informations__title"
            [innerHtml]="state.form.controls.eventTitle?.value"
          ></div>
          <p
            *ngIf="state.currentStep === 1"
            class="calendar-event__informations__duration"
          >
            <wr-icon
              [type]="iconService.types.Calendar"
              [shade]="iconService.shades.Shade10"
              class="m--r--3"
            ></wr-icon>
            {{
              (state.selectedDate
                | localizedDate
                  : "mediumDate"
                  : state.informations.dateDisplayType
                | async) + "&nbsp; - &nbsp;"
            }}
            <span class="txt--content--bold">
              {{
                state.selectedTime
                  | localizedTime : state.informations.dateDisplayType
                  | async
              }}
            </span>
          </p>

          <p class="calendar-event__informations__duration">
            <wr-icon
              [type]="iconService.types.Delay"
              [shade]="iconService.shades.Shade10"
              class="m--r--3"
            ></wr-icon>
            {{ state.informations.eventDuration }}
          </p>
          <p
            class="calendar-event__informations__phone-number"
            *ngIf="state.informations.phoneNumber?.length > 0"
          >
            <wr-icon
              [type]="iconService.types.Phone"
              [shade]="iconService.shades.Shade10"
              class="m--r--3"
            ></wr-icon>
            {{ state.informations.phoneNumber }}
          </p>
          <p
            class="calendar-event__informations__meeting-url"
            *ngIf="state.informations.eventMeetingUrl"
          >
            <wr-icon
              [type]="iconService.types.Video"
              [shade]="iconService.shades.Shade10"
              class="m--r--3"
            ></wr-icon>
            <a
              class="txt--primary--7"
              href="{{ state.informations.eventMeetingUrl }}"
              >{{ state.informations.eventMeetingUrl }}
            </a>
          </p>
          <p
            class="calendar-event__informations__address"
            *ngIf="state.informations.address?.length > 0"
          >
            <wr-icon
              [type]="iconService.types.MapMarkerAlt"
              [shade]="iconService.shades.Shade10"
              class="m--r--3"
            ></wr-icon>
            {{ state.informations.address }}
          </p>
          <p class="calendar-event__informations__participants-title">
            {{ "Calendar.blkCalendar.lblParticipants" | translate }}
          </p>
          <p class="calendar-event__informations__participants-names">
            {{ state.informations.participantFullNames }}
          </p>
          <div class="calendar-event__informations__description">
            <wr-icon
              [type]="iconService.types.InfoCircle"
              [shade]="iconService.shades.Shade5"
              [size]="iconService.sizes.SM"
              class="m--r--3"
            ></wr-icon>
            <div
              class="calendar-event__informations__description__content"
              [innerHtml]="state.form.controls.eventDescription?.value"
            ></div>
          </div>
          <div class="calendar-event__informations__timezone-info">
            {{
              "global.lblTimezoneInfo"
                | translate : { locations: state.informations.timezoneLocation }
            }}
          </div>
        </div>
      </div>
      <div
        class="col-12 col-md-8 col-lg-9 px-xl-4 calendar-event__calendar"
        *ngIf="state.currentStep === 0"
      >
        <p class="calendar-event__calendar__title">
          {{ "Calendar.blkCalendar.title" | translate }}
        </p>
        <app-calendar-event-calendar></app-calendar-event-calendar>
      </div>
      <div
        class="col-12 col-md-8 col-lg-9 px-xl-4 calendar-event__calendar"
        *ngIf="state.currentStep === 1"
      >
        <p class="calendar-event__calendar__title">
          {{ "Calendar.blkCalendar.title-step2" | translate }}
        </p>
        <app-candidate-form></app-candidate-form>
      </div>
    </div>
  </ng-container>
</ng-container>
