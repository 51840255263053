<ng-container *ngIf="state$ | async as state">
  <wr-container [btnList]="state.btnList">
    <div class="event">
      <div
        class="event__container row justify-content-center justify-content-lg-between"
      >
        <div class="event__date-selector d-block col-12 col-lg-6">
          <div class="d-flex align-items-center justify-content-center">
            <wr-btn
              [hierarchy]="enums.BtnHierarchies.Tertiary"
              [icon]="enums.IconTypes.ChevronLeft"
              [action]="previousMonth.bind(this)"
            >
            </wr-btn>
            <p class="event__date-selector__month">
              {{
                state.firstMonthDate
                  | localizedDate
                    : "MMMM yyyy"
                    : state.informations.dateDisplayType
                  | async
              }}
            </p>
            <wr-btn
              [hierarchy]="enums.BtnHierarchies.Tertiary"
              [icon]="enums.IconTypes.ChevronRight"
              [action]="nextMonth.bind(this)"
              class="d-lg-none"
            >
            </wr-btn>
          </div>
          <div class="event__date-selector__day">
            <div class="header-row day-row">
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-monday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-tuesday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-wednesday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-thursday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-friday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-saturday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-sunday" | translate
              }}</span>
            </div>
            <ng-container *ngFor="let week of state.firstMonth">
              <div class="day-row">
                <span
                  *ngFor="let day of week.days"
                  class="day-picker"
                  [ngClass]="{
                    available: day.available,
                    'other-month': day.otherMonth,
                    'is-past': day.isPast,
                    selected: state.selectedDateTime === day.time
                  }"
                  (click)="onDayClick(day)"
                >
                  {{ day.date.getDate() }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="event__date-selector d-none d-lg-block col-lg-6">
          <div class="d-flex align-items-center justify-content-center">
            <p class="event__date-selector__month">
              {{
                state.secondMonthDate
                  | localizedDate
                    : "MMMM yyyy"
                    : state.informations.dateDisplayType
                  | async
              }}
            </p>
            <wr-btn
              [hierarchy]="enums.BtnHierarchies.Tertiary"
              [icon]="enums.IconTypes.ChevronRight"
              [action]="nextMonth.bind(this)"
              class="d-none d-lg-block"
            >
            </wr-btn>
          </div>

          <div class="event__date-selector__day">
            <div class="header-row day-row">
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-monday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-tuesday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-wednesday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-thursday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-friday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-saturday" | translate
              }}</span>
              <span class="event__date-selector__day__header">{{
                "Calendar.blkCalendar.headerRow-sunday" | translate
              }}</span>
            </div>
            <ng-container *ngFor="let week of state.secondMonth">
              <div class="day-row">
                <span
                  *ngFor="let day of week.days"
                  class="day-picker"
                  [ngClass]="{
                    available: day.available,
                    'is-past': day.isPast,
                    'other-month': day.otherMonth,
                    selected: state.selectedDate === day.date
                  }"
                  (click)="onDayClick(day)"
                >
                  {{ day.date.getDate() }}
                </span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="event__hour-selector">
        <h2>
          {{
            state.selectedDate
              | localizedDate
                : "mediumDate"
                : state.informations.dateDisplayType
              | async
          }}
        </h2>
        <div
          class="hours"
          *ngIf="
            state.availabilities && state.availabilities.length > 0;
            else noAvailabilities
          "
        >
          <ng-container *ngFor="let day of state.availabilities">
            <wr-btn
              [color]="enums.BtnColors.Primary"
              [action]="onTimeClick(day)"
              class="hour-picker"
              [ngClass]="{
                active:
                  (day
                    | localizedTime : state.informations.dateDisplayType
                    | async) ===
                  (state?.selectedTime
                    | localizedTime : state.informations.dateDisplayType
                    | async)
              }"
            >
              {{
                day | localizedTime : state.informations.dateDisplayType | async
              }}
            </wr-btn>
          </ng-container>
        </div>
        <ng-template #noAvailabilities>
          <span class="event__hour-selector__no-availabities">{{
            "Calendar.blkCalendar.noAvailabilities-message" | translate
          }}</span>
        </ng-template>
      </div>
    </div>
  </wr-container>
</ng-container>
